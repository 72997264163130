import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import Ar from "vuetify/lib/locale/ar"

Vue.use(Vuetify);

export default new Vuetify({
    rtl: true,
    lang: {
        locales: { Ar },
        current: 'Ar'
    },
    breakpoint: {
        thresholds: {
            xs: 576,
            sm: 768,
            md: 992,
            lg: 1280,
        },
        mobileBreakpoint: 768
    },
    theme: {
        themes: {
            light: {
                white: '#fbfbfb',
                primary: '#0f4d96',
                secondary: '#b0bec5',
                chipsColor: '#d3e5ff',
                chipsColorActive: '#74aefe',

                accent: '#8c9eff',
                error: '#b71c1c',
                myOrange: '#fb5d33',
                myOrangeBg: '#f5e2dc',
                myGreen: '#2cac3e',
                myGreenBg: '#d1ecd4',
                myBlue: '#5da2ff',
                myBlueBg: '#d3e5ff',
            },
        },
    },
});
