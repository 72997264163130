export const LOGIN = "LOGIN"
export const LOGOUT = "LOGOUT"
export const CENTERS = "CENTERS"
export const ICON = "ICON"

export const SPECIALTIES = "SPECIALTIES"

export const STUDENTS = "STUDENTS"
export const STUDENT_PROFILE = "STUDENT_PROFILE"

export const TEACHERS = 'TEACHERS'
export const DEGREES = 'DEGREES'

export const ALL_STUDENTS = 'ALL_STUDENTS'