import * as types from '../mutations';

export default {
    namespaced: true,

    state: {
        teachers: [],
        degrees: [],
    },

    actions: {
        fetchAll({ rootState, commit, state }) {
            return state.teachers.length ? state.teacher : window.axios
                .get(rootState.auth.url + '/get-teachers')
                .then((res) => {
                    commit(types.TEACHERS, res.data);
                    return res.data;
                });
        },
        fetchDegrees({ rootState, commit, state }) {
            return state.degrees.length ? state.degrees : window.axios
                .get(rootState.auth.url + '/get-degree')
                .then((res) => {
                    commit(types.DEGREES, res.data);
                    return res.data;
                });
        },
        fetchProfile({ rootState }, { teacherId }) {
            return window.axios
                .get(rootState.auth.url + '/get-teacher', {
                    params: {
                        teacherId,
                    },
                })
                .then((res) => {
                    return res.data;
                });

        },
        fetchCourses({ rootState }, { teacherId }) {
            return window.axios
                .get(rootState.auth.url + '/get-teacher-courses', {
                    params: {
                        teacherId,
                    },
                })
                .then((res) => {
                    return res.data;
                });
        },
        fetchTests({ rootState }, { teacherId }) {
            return window.axios
                .get(rootState.auth.url + '/get-teacher-test', {
                    params: {
                        teacherId,
                    },
                })
                .then((res) => {
                    return res.data;
                });
        },
        fetchFunds({ rootState }, { teacherId }) {
            return window.axios
                .get(rootState.auth.url + '/get-teacher-funds', {
                    params: {
                        teacherId,
                    },
                })
                .then((res) => {
                    return res.data;
                });
        },
        fetchNotes({ rootState }, { teacherId }) {
            return window.axios
                .get(rootState.auth.url + '/get-teacher-notes', {
                    params: {
                        teacherId,
                    },
                })
                .then((res) => {
                    return res.data;
                });
        },
        fetchSchedule({ rootState }, { teacherId }) {
            return window.axios
                .get(rootState.auth.url + '/get-teacher-schedule', {
                    params: {
                        teacherId,
                    },
                })
                .then((res) => {
                    return res.data;
                });
        },
    },

    mutations: {
        [types.TEACHERS](state, teachers) {
            state.teachers = teachers;
        },
        [types.DEGREES](state, degrees) {
            state.degrees = degrees;
        },
    },
};
