<template>
	<div>
		<h2 class="primary--text text-h5 mb-2">الحضور</h2>
		<div
			class="d-flex align-center justify-space-around mx-auto"
			style="width: 420px">
			<v-btn
				x-small
				fab
				elevation="0"
				@click="prevDay">
				<v-icon>mdi-arrow-right</v-icon>
			</v-btn>
			<div
				@click="showDialog = true"
				class="primary pa-3 rounded-lg white--text text-center"
				style="width: 220px">
				{{ moment(day).format('YYYY/MM/DD') }}
			</div>
			<v-btn
				x-small
				fab
				elevation="0"
				@click="nextDay">
				<v-icon> mdi-arrow-left </v-icon>
			</v-btn>
		</div>
		<v-chip-group mandatory>
			<v-chip
				class="chipsColor"
				active-class="chipsColorActive"
				@click="isStudent = true">
				طلاب
			</v-chip>
			<v-chip
				class="chipsColor"
				active-class="chipsColorActive"
				@click="isStudent = false">
				مدرسون
			</v-chip>
		</v-chip-group>

		<v-row dense>
			<v-col cols="4">
				<v-select
					v-model="selectedSpecialtyId"
					@click:clear="
						selectedSpecialtyId = null;
						selectedCourseId = null;
					"
					:items="[{ SpecialtyName: 'الكل', SpecialtyId: null }, ...specialties]"
					item-text="SpecialtyName"
					item-value="SpecialtyId"
					label="الصف"
					outlined
					dense
					hide-details
					:clearable="!!selectedSpecialtyId"
					:menu-props="{ offsetY: true }"
					class="rounded-lg"></v-select>
			</v-col>
			<v-col cols="4">
				<v-select
					v-model="selectedCourseId"
					@click:clear="selectedCourseId = null"
					:disabled="!selectedSpecialtyId"
					:items="[
						{ CourseName: 'الكل', Id: null },
						...(specialties.find((e) => e.SpecialtyId === selectedSpecialtyId)?.Courses || []),
					]"
					item-text="CourseName"
					item-value="Id"
					label="الشعبة"
					outlined
					dense
					hide-details
					:clearable="!!selectedCourseId"
					:menu-props="{ offsetY: true }"
					class="rounded-lg"></v-select>
			</v-col>
		</v-row>

		<v-divider class="my-2"></v-divider>
		<v-card class="rounded-lg elevation-1 overflow-hidden mt-2">
			<v-data-table
				:headers="header"
				:items="
					attendance[isStudent ? 'StudentAttendance' : 'TeacherAttendance']?.filter(
						(e) => !e.HasAttend || e.IsLate || e.IsEarlyLeave,
					)
				"
				:loading="loadingAttendance"
				item-key="Id"
				dense
				:items-per-page="-1"
				hide-default-footer
				height="calc(100vh - 320px)"
				fixed-header
				mobile-breakpoint="0">
				<template v-slot:item.course="{ item }">
					{{ getCourseByCourseId(item.CourseId)?.CourseName }}
				</template>
				<template v-slot:item.specialty="{ item }">
					{{ getSpecialtyByCourseId(item.CourseId)?.SpecialtyName }}
				</template>
				<template v-slot:item.type="{ item }">
					{{ item.HasAttend ? (item.IsLate ? 'متاخر' : 'انصراف مبكر') : 'غائب' }}
					<span v-if="!isStudent && item.LateMinute">
						-
						{{ item.LateMinute }}
						د
					</span>
				</template>
			</v-data-table>
		</v-card>

		<v-dialog
			v-model="showDialog"
			width="300px">
			<v-date-picker
				elevation="3"
				@change="changeDate"
				scrollable
				locale="ar-SY"></v-date-picker>
		</v-dialog>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import moment from 'moment';

export default {
	name: 'AttendanceDialog',

	data() {
		return {
			loadingAttendance: false,

			selectedSpecialtyId: null,
			selectedCourseId: null,

			attendance: [],
			isStudent: true,

			day: this.moment(),

			header: [
				{
					value: 'Account.FullName',
					text: 'الاسم',
				},
				{
					value: 'type',
					text: 'النوع',
				},
				{
					value: 'specialty',
					text: 'الصف',
				},
				{
					value: 'course',
					text: 'الشعبة',
				},
			],

			showDialog: false,
		};
	},

	computed: {
		...mapState({
			specialties: (state) => state.specialties.specialties,
		}),
		...mapGetters({
			getSpecialtyByCourseId: 'specialties/getSpecialtyByCourseId',
			getCourseByCourseId: 'specialties/getCourseByCourseId',
		}),
	},

	methods: {
		changeDate(event) {
			this.day = this.moment(event);
			this.fetch();
			this.showDialog = false;
		},
		nextDay() {
			this.day = this.moment(this.day).add(1, 'days');
			this.fetch();
		},
		prevDay() {
			this.day = this.moment(this.day).add(-1, 'days');
			this.fetch();
		},

		fetch() {
			this.loadingAttendance = true;
			Promise.all([
				this.$store
					.dispatch('sideBar/fetchAttendance', {
						day: new Date(
							new Date(this.day).setHours(new Date().getTimezoneOffset() / -60, 0, 0, 0),
						).getTime(),
					})
					.then((res) => {
						this.attendance = res;
					}),
				this.$store.dispatch('specialties/fetchAll'),
			]).finally(() => {
				this.loadingAttendance = false;
			});
		},
		moment,
	},

	mounted() {
		this.fetch();
	},
};
</script>
