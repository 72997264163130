import * as types from '../mutations';

export default {
	namespaced: true,

	state: {
		centerId: null,
		user: null,
		permissions: null,

		url: '',

		centers: null,
	},

	actions: {
		login({ commit, dispatch }, { userName, centerId, password }) {
			return window.axios
				.post(`/center/${centerId}/manager/${userName}/login`, null, {
					headers: {
						'X-Password': password || '',
					},
				})
				.then((res) => {
					commit(types.LOGIN, {
						centerId: centerId,
						userName: userName,
						password: password,
						name: res.data.FullName,
						permissions: res.data.Permissions,
					});
					return res.data;
				});
		},
		logout({ commit }) {
			commit(types.LOGOUT);
		},

		fetchCenters({ commit }) {
			return window.axios.get(`/centers`).then((res) => {
				commit(types.CENTERS, res.data);
				return res.data;
			});
		},
	},

	mutations: {
		[types.LOGIN](state, user) {
			console.log('loging in...');

			state.user = user;
			state.centerId = user.centerId;
			state.permissions = user.permissions;

			state.url = `/center/${user.centerId}/manager/${user.userName}`;

			localStorage.setItem('auth', JSON.stringify(state.user));

			window.axios.defaults.headers['X-Password'] = user.password;
		},

		[types.LOGOUT](state) {
			console.log('loging out...');

			state.user = null;
			state.centerId = null;
			state.permissions = null;

			state.url = '';

			localStorage.setItem('auth', JSON.stringify(null));

			delete window.axios.defaults.headers['X-Password'];
		},

		[types.CENTERS](state, centers) {
			state.centers = centers;
		},
	},

	getters: {
		getCenter: (state) => {
			if (typeof state.centerId === 'number') {
				return state.centers.find((e) => e.Id === state.centerId).Name;
			}
			return null;
		},
	},
};
