<template>
	<div
		class="d-flex align-center justify-center"
		style="height: 80vh"
		v-if="loading">
		<v-progress-circular indeterminate> </v-progress-circular>
	</div>
	<div v-else>
		<!-- filter form -->
		<v-row
			class="ma-0"
			dense>
			<v-col
				cols="12"
				sm="6"
				md="5"
				lg="3">
				<v-text-field
					v-model="teacherName"
					label="اسم المدرس"
					outlined
					dense
					hide-details
					append-icon="mdi-magnify"
					class="rounded-lg"></v-text-field>
			</v-col>
			<v-col
				cols="12"
				sm="6"
				md="5"
				lg="3">
				<v-autocomplete
					v-model="degreeId"
					:items="degrees"
					item-value="Id"
					item-text="Name"
					label="الاختصاص"
					outlined
					dense
					hide-details
					clearable
					:menu-props="{ offsetY: true }"
					class="rounded-lg">
				</v-autocomplete>
			</v-col>
			<v-col cols="auto">
				<v-checkbox
					dense
					hide-details
					v-model="onlyUnDoneTest"
					label="العلامات غير المسلمة فقط"></v-checkbox>
			</v-col>
			<v-col
				cols="12"
				sm="auto"
				class="mr-0 mr-sm-3">
				<v-btn
					:disabled="loadingTeacher"
					:loading="loadingTeacher"
					color="primary"
					@click="filterTeachers">
					بحث
				</v-btn>
			</v-col>
		</v-row>
		<v-divider></v-divider>
		<!-- main -->
		<v-card class="rounded-lg elevation-1 overflow-hidden mt-6">
			<v-data-table
				:headers="header"
				:items="teacherArr || teachers"
				:loading="loadingTeacher"
				dense
				:items-per-page="-1"
				hide-default-footer
				height="calc(100vh - 170px)"
				fixed-header
				mobile-breakpoint="0">
				<!-- name template -->
				<template v-slot:item.Teacher="{ item }">
					{{ item.Teacher.Accounts.FirstName }}
					{{ item.Teacher.Accounts.LastName }}
				</template>
				<!-- subject template -->
				<template v-slot:item.Degree="{ item }">
					{{ item.Degree.Name }}
				</template>
				<!-- actions template -->
				<template v-slot:item.action="{ item }">
					<div class="actions mx-auto">
						<v-btn
							:to="{
								name: 'teacher',
								params: { id: item.Teacher.Id },
							}"
							title="الملف الشخصي للمدرس"
							depressed
							class="actions-btn myBlueBg">
							<v-icon class="myBlue--text"> mdi-account </v-icon>
						</v-btn>
					</div>
				</template>
			</v-data-table>
		</v-card>
	</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'AllTeachers',

	data() {
		return {
			loading: true,
			loadingTeacher: false,
			degreeId: null,
			teacherName: null,
			onlyUnDoneTest: false,

			teacherArr: null,

			header: [
				{
					text: 'اسم المدرس',
					value: 'Teacher',
					align: 'start',
					sortable: false,
				},
				{
					text: 'الاختصاص',
					value: 'Degree',
					align: 'start',
					sortable: false,
				},
				{
					text: 'علامات غير مسلمة',
					value: 'UnDoneTest',
					align: 'start',
					width: '20%',
				},
				{
					text: '',
					value: 'action',
					align: 'end',
					sortable: false,
					width: '10%',
				},
			],
		};
	},

	computed: {
		...mapState({
			teachers: (state) => state.teacher.teachers,
			degrees: (state) => state.teacher.degrees,
		}),
	},

	methods: {
		filterTeachers() {
			this.loadingTeacher = true;
			setTimeout(() => {
				this.loadingTeacher = false;
				this.teacherArr = this.teachers
					.filter((e) => {
						if (!this.degreeId) return true;
						return e.Degree.Id === this.degreeId;
					})
					.filter((e) => {
						if (!this.teacherName) return true;
						return (
							e.Teacher.Accounts.FirstName.includes(
								this.teacherName,
							) ||
							e.Teacher.Accounts.LastName.includes(
								this.teacherName,
							)
						);
					})
					.filter((e) =>
						this.onlyUnDoneTest ? e.UnDoneTest !== '-' : true,
					);
			}, 500);
		},
		fetch() {
			this.loading = true;
			Promise.all([
				this.$store.dispatch('teacher/fetchAll'),
				this.$store.dispatch('teacher/fetchDegrees'),
			]).finally(() => {
				this.loading = false;
			});
		},
	},

	mounted() {
		this.fetch();
	},

	metaInfo: {
		title: 'All teachers',
		titleTemplate: '%s | Edunix',
	},
};
</script>
