<template>
	<!-- error view -->
	<v-app v-if="isError">
		<v-layout
			fill-height
			align-center
			justify-center>
			<div class="d-flex flex-column text-center">
				<div class="mb-4">
					{{ isOnline ? 'حدث خطأ ما ' : 'تحقق من الاتصال بالانترنت' }}
				</div>
				<div class="d-flex">
					<v-btn
						@click="$router.go()"
						color="primary"
						class="me-3"
						outlined
						small>
						إعادة التحميل
					</v-btn>
					<v-btn
						:to="{ name: 'home' }"
						exact
						color="primary"
						outlined
						small>
						الصفحة الرئيسية
					</v-btn>
				</div>
			</div>
		</v-layout>
	</v-app>

	<!-- loader -->
	<div
		style="height: 80vh; display: flex; align-items: center; justify-content: center"
		v-else-if="loading">
		<v-progress-circular indeterminate> </v-progress-circular>
	</div>

	<!-- main content -->
	<v-app v-else>
		<navigation-drawer
			@openDialog="openDialog"
			app
			v-model="drawer"
			v-if="$route.name !== 'login'"></navigation-drawer>

		<app-bar
			app
			@change-navbar="drawer = !drawer"
			v-if="$route.name !== 'login'"></app-bar>

		<v-main
			app
			class="primary"
			id="main">
			<div
				class="white page"
				:class="{
					'rounded-br-xl': !$vuetify.breakpoint.mobile && $route.name !== 'login',
				}">
				<div class="px-5 py-1">
					<router-view />
				</div>
			</div>
		</v-main>

		<v-snackbar
			text
			shaped
			v-model="showSnackbar"
			:color="isErrorMessage ? 'error' : 'myGreen'">
			<div class="text-center">
				{{ message }}
			</div>
		</v-snackbar>

		<v-dialog
			v-model="showDialog"
			scrollable
			max-width="700px"
			transition="dialog-transition">
			<v-card
				class="pa-3"
				rounded="lg">
				<schedule-dialog v-if="scheduleDialog"></schedule-dialog>
				<marks-dialog v-if="marksDialog"></marks-dialog>
				<funds-dialog v-if="fundsDialog"></funds-dialog>
				<attendance-dialog v-if="attendanceDialog"></attendance-dialog>
			</v-card>
		</v-dialog>
	</v-app>
</template>

<script>
import AppBar from './components/AppBar.vue';
import NavigationDrawer from './components/NavigationDrawer.vue';
import ScheduleDialog from './components/ScheduleDialog.vue';
import MarksDialog from './components/MarksDialog.vue';
import FundsDialog from './components/FundsDialog.vue';
import AttendanceDialog from './components/AttendanceDialog.vue';

export default {
	name: 'App',
	components: {
		NavigationDrawer,
		AppBar,
		ScheduleDialog,
		MarksDialog,
		FundsDialog,
		AttendanceDialog,
	},
	data() {
		return {
			drawer: this.$vuetify.breakpoint.mdAndUp,
			openDrawerVar: false,

			message: '',
			isErrorMessage: true,
			showSnackbar: false,

			loading: true,

			isError: false,
			isOnline: true,

			showDialog: false,
			scheduleDialog: false,
			marksDialog: false,
			fundsDialog: false,
			attendanceDialog: false,
		};
	},
	methods: {
		openDialog(type) {
			this.showDialog = true;
			this.scheduleDialog = false;
			this.marksDialog = false;
			this.fundsDialog = false;
			this.attendanceDialog = false;

			switch (type) {
				case 'schedule':
					this.scheduleDialog = true;
					break;

				case 'marks':
					this.marksDialog = true;
					break;

				case 'funds':
					this.fundsDialog = true;
					break;

				case 'attendance':
					this.attendanceDialog = true;
					break;

				default:
					break;
			}
		},
	},
	created() {
		this.$store.dispatch('auth/fetchCenters').then(() => {
			if (this.$route.name === 'login') this.loading = false;
		});

		this.$eventBus.$on('show-snackbar', (isErrorMessage, message) => {
			this.message = message;
			this.isErrorMessage = isErrorMessage;
			this.showSnackbar = true;
		});
		this.$eventBus.$on('loader', (status) => {
			this.loading = status;
		});
		this.$eventBus.$on('error', (isOffline) => {
			this.isError = true;
			if (isOffline) {
				this.isOnline = false;
			}
		});

		window.addEventListener('online', () => {
			this.isOnline = true;
			this.isError = false;
			this.$router.go();
		});
	},
};
</script>

<style lang="scss">
@font-face {
	font-family: Fairuz;
	src: url('./assets/Fairuz/Fairuz-Light.otf');
	font-weight: 100 300;
}
@font-face {
	font-family: Fairuz;
	src: url('./assets/Fairuz/Fairuz-Normal.otf');
	font-weight: 400;
}
@font-face {
	font-family: Fairuz;
	src: url('./assets/Fairuz/Fairuz-Bold.otf');
	font-weight: 500 700;
}
@font-face {
	font-family: Fairuz;
	src: url('./assets/Fairuz/Fairuz-Black.otf');
	font-weight: 800 900;
}
*:not(.cart *) {
	font-size: 16px;
	@media (max-width: 768px) {
		font-size: 15px;
	}
	@media (max-width: 576px) {
		font-size: 14px;
	}
}

a {
	text-decoration: none !important;
}
span {
	font-size: inherit !important;
}
.page {
	height: 100%;
}

/** tables styles */
#app {
	.v-btn {
	}
}
.actions {
	a.actions-btn {
		border-radius: 10px !important;
		max-width: 35px;
		min-width: 35px !important;
		height: 35px !important;
		margin: 3px;
		margin-inline-start: 0.5rem;
		.v-icon {
			font-size: 1.3rem;
		}
		@media (max-width: 768px) {
			max-width: 30px;
			min-width: 30px !important;
			height: 30px !important;
		}
	}
}
$table-color-1: #f5f8ff;
$table-color-2: #e9f3ff;

.v-data-table {
	td,
	th {
		padding: 0 9px !important;
	}
	.v-data-table-header {
		tr {
			background: white !important;
		}
	}
	tr:nth-child(odd) {
		background: $table-color-1;
	}
	tr:nth-child(even) {
		background: $table-color-2;
	}
}

/** working hours */
.schedule-container {
	width: 100%;
	overflow-x: auto;
}
.schedule-container__inner {
	min-width: 1000px;
	overflow: hidden;
}

/** tabs in mobile */
.mobile-chips {
	.mobile-chips__item {
		width: 20%;
		@media (max-width: 768px) {
			width: 25%;
		}
		@media (max-width: 576px) {
			width: 33%;
		}
		padding: 3px 2px;
		span {
			margin: 0;
			width: 100%;
			text-align: center;
			justify-content: center;
		}
	}
}
.mobile-tab {
	cursor: pointer;
	width: 20%;
	@media (max-width: 768px) {
		width: 25%;
	}
	@media (max-width: 576px) {
		width: 33%;
	}
	text-align: center;
	font-size: 0.8rem;
	position: relative;
	background-color: transparent;
	margin-bottom: 5px;
	padding: 0 3px;
	z-index: 1;
	&:hover::before {
		background-color: #93c0fe;
	}
}
.mobile-tab::before {
	content: '';
	z-index: -1;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 3px;
	left: 3px;
	background-color: #d3e5ff;
	border-radius: 20px;
	transition: 0.3s;
}
.mobile-tab.active::before {
	background-color: #74aefe;
}

/** scroll bar */
::-webkit-scrollbar {
	width: 7px;
}
::-webkit-scrollbar-thumb {
	background: #888;
}
::-webkit-scrollbar-track {
	background: #f1f1f1;
}
::-webkit-scrollbar-thumb:hover {
	background: #555;
}
.home * {
	scrollbar-width: thin;
}
</style>
