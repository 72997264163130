import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import specialties from './modules/specialties';
import student from './modules/student';
import teacher from './modules/teacher';
import courses from './modules/courses';
import tests from './modules/tests';
import sideBar from './modules/sideBar';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {},
	getters: {},
	mutations: {},
	actions: {},
	modules: {
		auth,
		specialties,
		student,
		teacher,
		courses,
		tests,
		sideBar,
	},
});
